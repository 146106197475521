import React from 'react';
import DefaultLayout from '../../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../../layouts/TemplateColumn';
import { PageHeader, PageSection, DataTable, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, TextBlock, DefinitionList} from '../../../components';
import { StaticImage } from 'gatsby-plugin-image';
import cervical1LTable1 from "../../../assets/Cervical-826-interruption-safety-large-V3.png"
import cervical1LTable2 from "../../../assets/cervical-826-common-safety-v4.png"
import cervical1LTable3 from "../../../assets/cervical-826-safety-adverse-v4-mobile.svg"
import GlobalSafetyData, { globalSafetyBlock } from './_global_safety_data'
import { jobCodes } from '../../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `KEYNOTE-826: Selected Safety Data and Adverse Reactions`,
    keywords: `keytruda first-line combination therapy advanced cervical cancer adverse reactions`,
    description: `Learn about the immune-mediated adverse reactions for KEYTRUDA® (pembrolizumab) and the selected safety data from KEYNOTE-826.`,
    schemaJsonLD: [
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/cervical-cancer-first-line-combination-therapy/","@type":"ApprovedIndication","name":"KEYTRUDA®","alternateName":"pembrolizumab","description":"KEYTRUDA, in combination with chemotherapy, with or without bevacizumab, is indicated for the treatment of patients with persistent, recurrent, or metastatic cervical cancer whose tumors express programmed death ligand 1 (PD⁠-⁠L1) [combined positive score (CPS) ≥1] as determined by an FDA-approved test."}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/cervical-cancer-first-line-combination-therapy/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`
]
}

// image alts - so they do not repeat in mobile & desktop
const cervicalSafetyAlt = "Most Common Adverse Reactions or Laboratory Abnormalities Leading to Interruption of KEYTRUDA® (pembrolizumab) (≥2%) in KEYNOTE-826"
const cervicalSafetyAlt2 = "Most Common Adverse Reactions (≥20%) Occurring in Patients Receiving KEYTRUDA® (pembrolizumab) With Chemotherapy and With bevacizumab in KEYNOTE-826"
const cervicalSafetyAlt3 = "KEYNOTE-826 Adverse Reactions That Occurred in ≥20% of Patients"

//from /src/util/data/indications.js
const indicationId = 28;
const jobCode = jobCodes[24].jobCode;

const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const crossLinkContent = {
    sections : [
        {
            heading: "Additional Clinical Data",
            items: [
                { text: 'FIGO 2014 Stage III-IVA Cervical Cancer', url: '/efficacy/cervical-cancer-combination-chemoradiotherapy/' },
                { text: '2L Monotherapy Recurrent or Metastatic Cervical Cancer', url: '/efficacy/cervical-cancer-second-line-monotherapy/' },
            ]
        },
        {
            heading: "Related Information",
            items: [
                { text: 'Treatment Monitoring & Management', url: '/safety/monitoring-managing-adverse-reactions/' },
                { text: 'Dose Modifications', url: '/dosing/dose-modifications/' },
                        ]
        },
    ],
    interestLinks : {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
};

const safetyTable1Footnotes = [
    {
        label: "c.",
        text: "Chemotherapy (paclitaxel and cisplatin or paclitaxel and carboplatin)."
    },    
];

const safetyTable2Footnotes = [
    {
        label: "c.",
        text: "Chemotherapy (paclitaxel and cisplatin or paclitaxel and carboplatin)."
    },    
];
const safetyTable4Footnotes = [
    {
        label: "c.",
        text: "Chemotherapy (paclitaxel and cisplatin or paclitaxel and carboplatin)."
    },    
];
const safetyTable5Footnotes = [
    {
        label: "c.",
        text: "Chemotherapy (paclitaxel and cisplatin or paclitaxel and carboplatin)."
    },    
];
const safetyTable6Footnotes = [
    {
        label: "c.",
        text: "Chemotherapy (paclitaxel and cisplatin or paclitaxel and carboplatin)."
    }, 
    {
        label: "d.",
        text: "Graded per NCI CTCAE v4.0."
    },    
    {
        label: "e.",
        text: "Includes neuropathy peripheral, peripheral sensory neuropathy, peripheral motor neuropathy, peripheral sensorimotor neuropathy, paresthesia."
    },    
    {
        label: "f.",
        text: "Includes rash, rash maculo-papular, rash erythematous, rash macular, rash papular, rash pruritic, rash pustular."
    },   
    {
        label: "g.",
        text: "Includes fatigue, asthenia."
    },         
];

const anchorLinkModelData = [
    { label: 'Incidence of Selected Immune-Mediated Adverse Reactions' }, 
    { label: 'Management of Selected Immune-Mediated Adverse Reactions' }, 
    { label: 'Selected Safety Data from KEYNOTE&#8288;-&#8288;826' }
];

const Page = ({ location }) => {

    return (
        <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={ pageMeta } location={ location } >
            <div data-template-name="template-e">
                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Selected Adverse Reactions" />
                   <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId}/>
                   </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumnWithSidebarContainer>
                {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">

                        <PageSection bgColor="cloud" title="Severe and Fatal Immune-Mediated Adverse Reactions">
                            <TextBlock>{globalSafetyBlock}</TextBlock>
                            <AnchorLinks items={anchorLinkModelData} />
                        </PageSection>

                        <GlobalSafetyData />

                        <PageSection title={anchorLinkModelData[2].label}>
                            <TextBlock footnotes={safetyTable1Footnotes}>
                                <p>A total of 616 patients, regardless of tumor PD&#8288;-&#8288;L1 expression, received KEYTRUDA 200 mg and chemotherapy with or without bevacizumab (n=307) every 3 weeks or placebo and chemotherapy with or without bevacizumab (n=309) every 3 weeks. </p>
                                <p>The median duration of exposure to KEYTRUDA was 9.9 months (range: 1 day to 26 months).</p>
                                <p><b>Fatal adverse reactions occurred in 4.6% of patients treated with KEYTRUDA + chemotherapy<sup>c</sup> with or without bevacizumab, including (number of cases):</b></p>
                                <ul>
                                    <li>Hemorrhage (3)</li>
                                    <li>Sepsis (2)</li>
                                    <li>Unknown causes (2)</li>
                                    <li>Acute myocardial infarction (1)</li>
                                    <li>Autoimmune encephalitis (1)</li>
                                    <li>Cardiac arrest (1)</li>
                                    <li>Cerebrovascular accident (1)</li>
                                    <li>Femur fracture with perioperative pulmonary embolus (1)</li>
                                    <li>Intestinal perforation (1)</li>
                                    <li>Pelvic infection (1)</li>
                                </ul>
                            </TextBlock>
                            <TextBlock 
                                footnotes={safetyTable2Footnotes}
                            >
                                <p><b>Serious adverse reactions occurred in 50% of patients receiving
                                KEYTRUDA + chemotherapy<sup>c</sup> with or without bevacizumab. Serious
                                adverse reactions in ≥3% of patients treated with KEYTRUDA +
                                chemotherapy<sup>c</sup> with or without bevacizumab were:</b></p>
                                <ul>
                                    <li>Febrile neutropenia (6.8%)</li>
                                    <li>Urinary tract infection (5.2%)</li>
                                    <li>Anemia (4.6%)</li>
                                    <li>Acute kidney injury (3.3%)</li>
                                    <li>Sepsis (3.3%)</li>
                                </ul>
                                <p>KEYTRUDA was discontinued for adverse reactions in 15% of patients. The most common adverse reaction resulting in permanent discontinuation of KEYTRUDA (≥1%) was colitis (1%).</p>
                            </TextBlock>
                            {/* Table 1 */}
                            <DataTable 
                                mobileImage={() => <img src={cervical1LTable1} style={{width:'100%', maxWidth:'500px', margin: '0 auto'}} alt={cervicalSafetyAlt}/>}
                                desktopImage={() => <StaticImage quality={90} placeholder="DOMINANT_COLOR" 
                                //src="../../../assets/Cervical-826-interruption-safety-large-V3.png" 
                                src='../../../assets/cervical-safety-826.png'
                                alt={cervicalSafetyAlt}
                                />}
                                title="Adverse reactions leading to interruption of KEYTRUDA occurred in 66% of patients"
                                limitation="The most common adverse reactions or laboratory abnormalities leading to interruption of KEYTRUDA (≥2%) are listed below."
                                footnotes={safetyTable4Footnotes}
                            />
                            {/* Table 2 */}
                            <DataTable 
                                mobileImage={() => <img src={cervical1LTable2} style={{width:'100%', maxWidth:'500px', margin: '0 auto'}} alt={cervicalSafetyAlt2}/>}
                                desktopImage={() => <StaticImage quality={90} placeholder="DOMINANT_COLOR" src="../../../assets/cervical-826-common-safety-v4.png" alt={cervicalSafetyAlt2}/>}
                                title="Most common (≥20%) adverse reactions in patients treated with KEYTRUDA + chemotherapy<sup>c</sup> and bevacizumab" 
                                footnotes={safetyTable5Footnotes}
                            />
                            {/* Table 3 */}
                            <DataTable 
                                mobileImage={() => <img src={cervical1LTable3} style={{width:'100%', maxWidth:'500px', margin: '0 auto'}} alt={cervicalSafetyAlt3}/>}
                                desktopImage={() => <StaticImage quality={90} placeholder="DOMINANT_COLOR" src="../../../assets/cervical-826-safety-adverse-v4.png" alt={cervicalSafetyAlt3}/>}
                                title="Adverse Reactions Occurring in ≥20% of Patients Receiving KEYTRUDA in KEYNOTE&#8288;-&#8288;826" 
                                footnotes={safetyTable6Footnotes}
                                definitions="CTCAE v4.0 = Common Terminology Criteria for Adverse Events version 4.0; NCI = National Cancer Institute; Q3W = every 3 weeks."
                            />
                        </PageSection>
                    </TemplateColumn>
                    {/* End Main Column */}

                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>

                </TemplateColumnWithSidebarContainer>

                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent}/>
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout>
    );
};

export default Page;
